.App {
  text-align: center;
  padding: 20px;
}

h1 {
  color: #333;
}

table {
  margin: 20px auto;
  border-collapse: collapse;
  width: 80%;
}

table, th, td {
  border: 1px solid #ccc;
  padding: 10px;
}

th {
  background-color: #f4f4f4;
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
